import i18n from '@/plugins/i18n';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { auth } from '@/services/firebase'
import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

const requiresAuth = true,
  backlink = true;

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale(tr|en|ru)?',
    name: 'home',
    component: HomeView
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import(/* webChunkName: "menu" */ '@/views/MenuView.vue')
  },
  {
    path: "/menu/:categoryId",
    name: "prices",
    component: () => import(/* webChunkName: "menu" */ '@/views/MenuView.vue')
  },
  {
    name: "login",
    path: "/management/login",
    component: () => import(/* webChunkName: "management-login" */ '@/views/manager/LoginView.vue')
  },
  {
    path: "/management",
    name: "management",
    component: () => import(/* webChunkName: "management" */ '@/layouts/ManagerLayout.vue'),
    children: [
      {
        name: "managementDefault",
        path: "",
        redirect: "/management/categories",
      },
      {
        name: "managementCategories",
        path: "categories",
        component: () => import(/* webChunkName: "management-categories" */ '@/views/manager/CategoriesView.vue'),
        meta: { requiresAuth, title: "Kategoriler" }
      },
      {
        name: "managementPrices",
        path: "prices/:id",
        component: () => import(/* webChunkName: "management-prices" */ '@/views/manager/PricesView.vue'),
        meta: { requiresAuth, backlink }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) next('/management/login');
  else if (to.params.locale) {
    i18n.global.locale.value = to.params.locale as string;
    cookies.set("_i18n", to.params.locale as string, "30d");
    next('/menu');
  }
  else next();
})

export default router
