import { auth } from './services/firebase';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import store from '@/store';
import "animate.css";
import i18n from "@/plugins/i18n";

loadFonts()

auth.onAuthStateChanged(() => {
  createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(i18n)
    .mount('#app')
});

