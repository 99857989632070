import { CategoryItem, PriceItem } from './types';
import { initializeApp } from "firebase/app";
import { collection, deleteDoc, doc, Firestore, getDoc, getDocs, getFirestore, orderBy, Query, query, writeBatch } from 'firebase/firestore';
import { Analytics, getAnalytics } from "firebase/analytics";
import { browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes, UploadResult } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDhaepaSxvGV61Duu4db3SayhAeQmtj9rg",
    authDomain: "nihotel.firebaseapp.com",
    projectId: "nihotel",
    storageBucket: "nihotel.appspot.com",
    messagingSenderId: "194077958669",
    appId: "1:194077958669:web:2c8965e2356d8ae01ceb44",
    measurementId: "G-WQFCD98XXY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics: Analytics = getAnalytics(app);
export const db: Firestore = getFirestore(app);
export const auth = getAuth(app);
export const perf = getPerformance(app);
export const storage = getStorage(app);

export function menuCategories() {
    const qRef = collection(db, "menu");
    const q: Query = query(qRef, orderBy("index"));
    return getDocs(q);
}

export function menuCategory(id: string) {
    const docRef = doc(db, `/menu/${id}`);
    return getDoc(docRef);
}

export function saveCategories(categories: CategoryItem[]) {
    const batch = writeBatch(db);
    categories.forEach((cat: CategoryItem) => {
        let { id } = cat;
        const _cat = { ...cat };
        delete _cat.id;

        if (!id) id = doc(collection(db, "menu")).id; //auto generated id

        if (id) {
            const _ref = doc(db, `menu/${id}`);
            batch.set(_ref, _cat, { merge: true });
        }
    });
    return batch.commit();
}

export function deleteCategory(id: string) {
    const docRef = doc(db, `menu/${id}`);
    return deleteDoc(docRef);
}

export function menuPrices(categoryId: string) {
    const qRef = collection(db, `/menu/${categoryId}/items`);
    const q: Query = query(qRef, orderBy("index"));
    return getDocs(q);
}

export function savePrices(categoryId: string, prices: PriceItem[]) {
    const batch = writeBatch(db);
    prices.forEach((prc: PriceItem) => {
        let { id } = prc;
        const _price = { ...prc };
        delete _price.id;

        if (!id) id = doc(collection(db, `/menu/${categoryId}/items`)).id;

        if (id) {
            const _ref = doc(db, `/menu/${categoryId}/items/${id}`);
            batch.set(_ref, _price, { merge: true });
        }
    });
    return batch.commit();
}

export function deletePrice(categoryId: string, priceId: string) {
    const docRef = doc(db, `/menu/${categoryId}/items/${priceId}`);
    return deleteDoc(docRef);
}

export async function login(email: string, password: string) {
    return setPersistence(auth, browserSessionPersistence)
        .then(() => {
            return signInWithEmailAndPassword(auth, email, password);
        });
}

export function logOut() {
    return signOut(auth);
}

interface IFile {
    responsive: string,
    buffer: number[],
    type: string
}

export function uploadFile(id: string, files: IFile[]) {
    return new Promise((resolve, reject) => {
        const promises: Promise<UploadResult>[] = [];

        for (let n = 0; n < files.length; n++) {
            const file: IFile = files[n];
            if (file.responsive !== "xxl") {
                const dataRef = storageRef(storage, `/images/${id}/${file.responsive}.${file.type}`);
                promises.push(uploadBytes(dataRef, Uint8Array.from(file.buffer), { contentType: "image/webp" }));
            }
        }

        Promise.all(promises)
            .then(async (snaps: UploadResult[]) => {
                const results: { [key: string]: string, } = {};
                for (let i = 0; i < snaps.length; i++) {
                    const snap: UploadResult = snaps[i];
                    const snapRef = storageRef(snap.ref.storage, snap.ref.fullPath);
                    const url = await getDownloadURL(snapRef);
                    console.log("[upload]", url);
                    results[snap.metadata.name.split(".")[0]] = url;
                }
                resolve(results);
            })
            .catch(reject);

    });
}