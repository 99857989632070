<template>
  <v-app>
    <v-main>
      <router-view v-slot="{ Component }">
        <v-scroll-x-transition mode="in-out"
                               :hide-on-leave="false">
          <KeepAlive>
            <component :is="Component" />
          </KeepAlive>
        </v-scroll-x-transition>
      </router-view>

      <!-- <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition> -->
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
html,
body,
#app {
  overflow: auto !important;
}

.v-application,
.v-application__wrap,
.v-main,
.v-main__wrap {
  overflow: auto;
}

.v-main__wrap {
  max-height: 100vh;
}

.sticky {
  position: sticky;

  &.top {
    top: 1rem;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  position: relative;
  float: left;
  transform: translateX(10px);
  opacity: 0;
}

.bordered-wrapper {
  border: 2px solid #1e432f;
  min-height: 100%;
  width: 100%;
  overflow: auto;
}

.bg-green.bg-image {
  /* background: #1e432f url("@/assets/olive.svg") no-repeat fixed center top !important; */
  background-size: auto 40vh !important;
}

.bg-image:not(.bg-green) {
  /* background: url("@/assets/olive-overlayed.svg") no-repeat fixed right bottom !important; */
  background-size: auto 30vh !important;
}
</style>
