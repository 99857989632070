/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts() {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

  webFontLoader.load({
    google: {
      families: ['Open+Sans:400,500&display=swap', 'Dancing+Script:400,500,700&display=swap', 'Quicksand:500&display=swap'],
    },
  })
}
