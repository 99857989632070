import { useCookies } from 'vue3-cookies';
import { createI18n } from 'vue-i18n';
import messages from "@/locales";

const { cookies } = useCookies();
const cookie_locale = cookies.get("_i18n");

const i18n = createI18n({
    legacy: false,
    locale: cookie_locale || process.env.VUE_APP_I18N_LOCALE || 'tr',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'tr',
    messages
});

export default i18n;