// Styles
import '@/scss/variables.scss';

// Vuetify
import { createVuetify } from 'vuetify';

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: "dark",
      themes: {
        dark: {
          colors: {
            primary: "#b57d2f",
            green: "#5a8a5d",
            surface: "#000000",
            background: "#000000"
          }
        }
      }
    },
    defaults: {
      VCard: {
        elevation: 5
      },
      VTextField: {
        variant: "outlined",
        density: "comfortable",
        color: "primary"
      },
      VToolbar: {
        color: "primary",
        density: "comfortable",
        rounded: true,
        elevation: 5
      }
    }
  }
)
